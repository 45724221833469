<template>
 <section id="dashboard-ecommerce">
  <b-card no-body class="mb-0">
    <b-card-body class="pb-1">
      <h4 style="font-family: montserrat !important">
        KALİTE VE ÇEVRE POLİTİKAMIZ
      </h4>
      <br />
      <p style="font-family: montserrat !important">
        Topluma, çevreye, müşterilerine, hissedarlarına, tedarikçilerine ve
        çalışanlarına karşı, sorumluluklarının bilincinde bir kuruluş olarak ilk
        hedefimiz; Ulaştığımız otomotiv sektörü kalite seviyesini, kalite ve
        çevre yönetim sistemini, yasal şartlar ve etik kurallar çerçevesinde
        sürekli iyileştirerek, müşteri memnuniyetini en üst seviyeye
        çıkarmaktır.<br /><br />

        Kuruluşumuzun yakalamış olduğu kalite seviyesine, temel gücümüzü
        oluşturan çalışanlarımızın katkıları, eğitimi ve memnuniyetinin
        sağlanması ile ulaşılmıştır. Bu seviyeyi her geçen gün daha iyiye
        götüreceğimize ve şirketimizin marka değerinin artırılacağına inancımız
        sonsuzdur.<br /><br />
        Şirketimize mal ve hizmet üreten tedarikçilerimiz, ürünlerimizin
        kalitesine etki eden en önemli unsurlardan birisidir. Kalite ve çevre
        sistemlerimizin birer parçası olan tedarikçilerimizle karşılıklı güven
        ve dayanışma içinde iş birliğimizi geliştirmeyi amaçlıyoruz.<br /><br />
        Sorumluluklarımız çerçevesinde, sürekli iyileştirme bilinci ile etkin
        bir atık yönetimi uygulayarak çevreye olan zararlı etkilerimizi en aza
        indirmek, kirliliğin önlenmesini sağlamak, doğal kaynak kullanımını
        kontrollü bir şekilde elimizden geldiğince azaltmak, uygunluk
        yükümlülüklerini yerine getirmek, tüm bunları sürekli eğitimlerle ve
        etkili bir iletişim ile paydaşlarımıza aktarmak amacındayız.<br /><br />
        Modern ve güvenilir bir işletme olarak; Kalite, şirketimizin tüm
        faaliyetleri için temel şart ve öncelikli hedefimizdir. Sahip olduğumuz
        işletme kültürü ve oluşturduğumuz toplam kalite bilincinin sistematik
        olarak geliştirilmesi, Ünüvar Elektronik Üst Yönetimi ve tüm çalışanları
        için en önemli görevdir.
      </p>
      <br />
      <p
        style="
                font-family: montserrat !important;
                font-weight: bold;
                text-align: center;
              "
      >
        Genel Müdür
      </p>
      <p
        style="
                font-family: montserrat !important;
                font-weight: bold;
                text-align: center;
              "
      >
        MUZAFFER TAŞOĞLU
      </p>
      <p style="font-family: montserrat !important">
        Revizyon No: 5 <br />
        Revizyon Tarihi: 06/07/2018
      </p>

      <div class="row" style="float: center">
        <div class="col" width="33.3333%" style="float: center !important">
          <h3>ISO 9001</h3>

          <p style="float: center !important">
            <a
              href="http://www.unuvar.com/UE-03Default/9001.pdf"
              target="_blank"
              ><img
                src="https://i.shaesk.com/Media/Images/f8ff54bd-5e5c-40d4-a919-176b164300e8.jpg"
                width="287"
                height="287"
                id="Image22"
                alt="sertifika"
            /></a>
          </p>

          <p></p>
        </div>
        <div class="col" width="33.3333%" style="float: center !important">
          <h3>ISO 14001</h3>
          <p>
            <a
              href="http://www.unuvar.com/UE-03Default/14001.pdf"
              target="_blank"
              ><img
                src="https://i.shaesk.com/Media/Images/9f146ca6-b6cc-473b-9828-d60fb09132ad.jpg"
                width="287"
                alt="sertifika"
                height="287"
                id="Image14"
            /></a>
          </p>
        </div>
        <div class="col" width="33.3333%" style="float: center !important">
          <h3>IATF 16949</h3>

          <p>
            <a
              href="http://www.unuvar.com/UE-03Default/16946.pdf"
              target="_blank"
              ><img
                src="https://i.shaesk.com/Media/Images/ac27ea9b-4893-4507-948c-d047346fe0e7.jpg"
                width="287"
                height="287"
                alt="sertifika"
                id="Image18"
            /></a>
          </p>
        </div>
      </div>
    </b-card-body>
  </b-card>
 </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BTableSimple,
  BTr,
  BTbody,
  BTd
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BTableSimple,
    BTr,
    BTbody,
    BTd
  }
};
</script>
